import portret from '../../images/about.jpg';

function Announcement() {
  return (
    <div
      id="announcement"
      // className="relative isolate px-6 pt-14 lg:px-8 bg-white"
      style={{
        backgroundColor: 'gray',
      }}
    >
      <div className="mx-auto max-w-2xl py-10 sm:py-15 lg:py-20">
        <div className="text-center py-5">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Coming Soon
          </h1>
          <p className="mt-6 text-lg leading-8 text-white">
            Our website is getting a makeover, and you can expect the final
            version to be ready in October. Thanks for your understanding as we
            make improvements!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
