import portret from '../../images/about.jpg';
import Antonina from '../../images/Antonina.jpg';
import intolikeness from '../../images/intolikeness.jpg';
import ImageWithin from '../../images/ImageWithin.jpg';
import music from '../../images/music.jpg';

function What() {
  return (
    <div
      id="what"
      className="bg-sky py-10 px-6 pt-14 lg:px-8 bg-white"
      // style={{
      //   background:
      //     'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/img/music.jpg)  no-repeat center local ',
      //   backgroundSize: 'cover',
      // }}
    >
      <div className="mx-auto max-w-2xl py-10 sm:py-15 lg:py-20">
        <div className="text-center">
          {/* <img className="object-scale-down h-48 w-96 ..." src={portret} /> */}
          <h1 className="text-4xl font-bold tracking-tight text-blue sm:text-6xl">
            What do we do
          </h1>
          <p className="mt-6 text-lg leading-8 text-darkSteel">
            The OSUDemocracy foundation aims to organise seasonal events at the
            National Arts Centre in Ottawa. In a few years, we plan to conduct
            the same events in other cities of Canada and abroad. Our events
            will start with a presentation to promote the visibility of
            democracy, showing why is the visibility important and how we are
            trying to increase it in our society locally and internationally. We
            believe that democracy is better understood by the public if the
            minority languages, cultures, history, and personal stories are
            presented in a combination of various art forms. We will invite
            everyone who would like to give a 4–10- minute informative visual
            performance which is an information passed through any form of art.
          </p>
          <p className="mt-6 text-lg leading-8 text-darkSteel">
            The future informative performers can demonstrate an equipment, a
            product, or a talent with democratic, historical, educational,
            multicultural, personal, or environmental improvements that will
            move our society towards the progress while respecting democracy. A
            participant who shows the most effective combination of art and
            information will win the competition. Their prizes we must
            determine.
          </p>
          <p className="mt-6 text-lg leading-8 text-darkSteel">
            The performers will be divided by age: 5 to 9 years old, 10 to 15
            years old and adults (16+). In the final performance of the year,
            all groups compete against each other. We have only one final
            winner; two others will win the second and the third places. We plan
            to have an opening ceremony on December 9, 2023, or around that
            date. This event will give a chance to many teachers, inventors, and
            artists from Canada and to many newcomers from Ukraine to express
            themselves on stage and to have an opportunity to grow. We also want
            to maintain a positive spirit of Ukrainians and to spread the belief
            that democracy will prevail. We will encourage educational
            institutions to participate in our events. Such educational and
            artistic events will help professors and schoolteachers to improve
            their teaching approaches.
          </p>
          <p className="mt-6 text-lg leading-8 text-darkSteel">
            We plan to have an opening ceremony on December 9, 2023, or around
            that date. This event will give a chance to many teachers,
            inventors, and artists from Canada and to many newcomers from
            Ukraine to express themselves on stage and to have an opportunity to
            grow. We also want to maintain a positive spirit of Ukrainians and
            to spread the belief that democracy will prevail. We will encourage
            educational institutions to participate in our events. Such
            educational and artistic events will help professors and
            schoolteachers to improve their teaching approaches.
          </p>
        </div>
      </div>
    </div>
  );
}

export default What;
