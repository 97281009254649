import Who from '../features/Who';
import What from '../features/What';
import How from '../features/How';
import Contact from '../features/Contact';
import Announcement from '../features/Announcement';

function Main() {
  return (
    <div>
      <Who />
      <Announcement />
      <What />
      <How />
      <Contact />
    </div>
  );
}

export default Main;
