function Who() {
  return (
    <div
      id="who"
      className="relative px-6 py-5"
      style={{
        backgroundPosition: 'center',
        background:
          'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/img/about.jpg) no-repeat center fixed',
        backgroundSize: 'cover',
      }}
    >
      {/* <div className="mx-auto max-w-2xl py-5 sm:py-10 lg:py-15"> */}
      <div className="mx-auto max-w-2xl py-5 sm:py-10 lg:py-15">
        <div className="text-center py-24">
          <h1 className="text-4xl font-bold tracking-tight text-yelowish sm:text-6xl">
            Slava Ukraini!
          </h1>
          <p className="mt-6 text-lg leading-8 text-white">
            We are inviting you to discover a new non-for-profit organisation.
            Thank you for your support!
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            OSUDemocracy is a non-for-profit organisation with an aim to gain a
            charitable status this year. Its{' '}
            <span className="text-yelowish">mission</span> is to make democracy
            inspiring and visible through a mix of art, visuals, and
            information.
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            <span className="text-yelowish">Vision</span>: to become the global
            democracy booster through a mix of art, visuals, and information.
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            <span className="text-yelowish">Financial goals</span>: to finance
            programs that use art for teaching purposes and for developing
            critical thinking. We also set a challenge to help rebuild at least
            5 schools in Ukraine and make them accessible for individuals with
            disabilities.
          </p>
          <h2 className="mb-2 text-lg font-semibold text-yelowish">
            Since the full-scale invasion of Ukraine:
          </h2>
          <ol className="pl-5 mt-2 space-y-4 text-left list-decimal list-inside text-white">
            <li>
              we create events and participate in local events in support for
              Ukraine and democracy.
            </li>
            <li>
              we update events related to Ukraine and to democracy, inform
              Canadians about Ukrainian culture, share the latest news and
              promote individuals, organisations and businesses that help
              Ukraine on our Facebook Group Ottawa Supports Ukraine –
              OSUDemocracy.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default Who;
