function Footer() {
  return (
    <footer className="bg-lightBlue text-center text-yelowish">
      {/* Copyright section */}
      <div
        className="p-4 text-center"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      >
        © 2023 Copyright: OSUDemocracy. Designed by:<span> </span>
        <a href="https://vulcano.top/" target="_blank">
          vulcano.top
        </a>
      </div>
    </footer>
  );
}

export default Footer;
