import portret from '../../images/about.jpg';

function Contact() {
  return (
    <div
      id="contact"
      className="relative isolate px-6 pt-14 lg:px-8 bg-white"
      style={{
        backgroundPosition: 'center',
        background:
          'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/img/contact.jpg) no-repeat center fixed',
        backgroundSize: 'cover',
      }}
    >
      {/* <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4682A9] to-[#99e1f7] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        ></div>
      </div> */}
      {/* <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56"> */}
      <div className="mx-auto max-w-2xl py-10 sm:py-15 lg:py-20">
        <div className="text-center py-5">
          {/* <img className="object-scale-down h-48 w-96 ..." src={portret} /> */}
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Contact
          </h1>
          <p className="mt-6 text-lg leading-8 text-white">
            For those who desire to become OSUDemocracy Stars this year, please
            send your performance description and a link to a video directly to
            the founder of OSUDemocracy:
          </p>
        </div>
        <div className="text-right">
          <h3 className="text-1xl font-bold tracking-tight sm:text-2xl text-white">
            Email:{' '}
            <span className="text-lightBlue">Nataliya.Senyuk@gmail.com</span>
          </h3>
          <h3 className="text-1xl font-bold tracking-tight sm:text-2xl text-white">
            Facebook:
            <a
              href="https://www.facebook.com/groups/1370107486753122"
              target="_blank"
              type="button"
              // className="m-1 h-9 w-9 leading-normal text-steel ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              className="m-1 leading-normal text-lightBlue ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
            >
              Ottawa supports Ukraine - OSUDemocracy
            </a>
          </h3>
        </div>
      </div>
      {/* <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(150%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#4682A9] to-[#749BC2] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        ></div>
      </div> */}
    </div>
  );
}

export default Contact;
