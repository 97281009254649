import { useState } from 'react';

const videoCollaborations = [
  {
    id: '1',
    name: 'Малюй',
    img: 'url(/img/malui.jpg)',
    about: '',
    link: 'https://www.youtube.com/watch?v=NA0MTHh7Kfk',
    textAuthor: 'Ludmyla Derkach',
    composer: 'Euriy Derkach',
    singer: 'Olena Kushnir',
  },
  {
    id: '2',
    name: 'Малюй (Remix)',
    img: 'url(/img/maluiRemix.jpg)',
    about: '',
    link: 'https://www.youtube.com/watch?v=FXJJz0nScA4',
    textAuthor: 'Ludmyla Derkach',
    composer: 'Euriy Derkach',
    singer: 'Olena Kushnir',
  },
  {
    id: '3',
    name: 'Хто ми',
    img: 'url(/img/htomy.jpg)',
    about: '',
    link: 'https://www.youtube.com/watch?v=N1HLz6KGr94',
    textAuthor: 'Ludmyla Derkach',
    composer: 'Euriy Derkach',
    singer: 'Olena Kushnir',
  },
  {
    id: '4',
    name: 'Lullaby (Колискова)',
    img: 'url(/img/lullaby.jpg)',
    about: '',
    link: 'https://www.youtube.com/watch?v=RqHnwxlpZvA',
    textAuthor: 'Ludmyla Derkach',
    composer: 'Euriy Derkach',
    singer: 'Olena Kushnir',
  },
];

function How() {
  const [videos, setVideos] = useState(videoCollaborations);

  return (
    <div id="how" className="px-6 p-14 lg:px-8 ">
      <div className="mx-auto max-w-3xl py-10 sm:py-15 lg:py-20">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-steel sm:text-6xl mb-6">
            How to help
          </h1>

          <ul className="space-y-4 text-left">
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span className="text-darkSteel">
                Donate: Your generosity acts as a cornerstone upon which we
                build impactful programs that touch lives directly.
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span className="text-darkSteel">
                Volunteer: Immerse yourself in hands-on experiences that bring
                about real-world transformation.
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span className="text-darkSteel">
                Spread the Word: Become a vocal advocate for our cause,
                amplifying our message and expanding our reach.
              </span>
            </li>
          </ul>

          <p className="mt-6 text-lg leading-8 text-darkSteel">
            Sorry, we don’t have a donation button yet. Our website will be
            completed soon!
          </p>
        </div>
      </div>
    </div>
  );
}

export default How;
